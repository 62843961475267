import React, { useEffect } from "react"
import { graphql } from "gatsby"
// Hooks / Querys
import { QueryFragments } from "../GraphQL/queryFragments"  //eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

// Components
import { PageMeta } from "../components/pageMeta"
import BlockZone from "../components/blocks/blockZone"

const IndexPage = ({ data: { sanityHomePage } }) => {

  const {
    setGlobalTheme,
    toggleHideHeader,
    toggleHideFooter,
    toggleHeaderUnderline,
  } = useAppState()

  const { meta, pageBlocks } = sanityHomePage || {}

  useEffect(() => {
    setGlobalTheme("dark")
    toggleHideFooter(false)
    toggleHideHeader(false)
    toggleHeaderUnderline(false)
  }, [
    setGlobalTheme,
    toggleHideFooter,
    toggleHideHeader,
    toggleHeaderUnderline,
  ])
  return (
    <>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
}

export default IndexPage


export const pageQuery = graphql`
  query homePageQuery {
    sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZoneBlocks
      }
    }
  }
`

